import React from 'react'

import Layout from '../components/layout/rootLayout.js'
import ReportPage from '../components/report/reportpage.js'

const Default = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <ReportPage />
  </Layout>
)

export default Default
