import React, { Fragment } from 'react'
import Nav from '@components/nav/nav'
import { injectIntl } from 'react-intl'
import { StyledMessages } from '@components/common/styledMessage'
import { LandingRow, LandingRowContent } from '@src/style/globalStyle'
import { IndexpageRoot } from '@components/main/styles'

export default injectIntl(({ intl: { locale } }) => {
  return (
    <IndexpageRoot>
      <Nav defaultIndex={0} membersArea />
      <LandingRow>
        <LandingRowContent>
          <StyledMessages
            id='report'
            values={{
              ul: (...msg) => <ul>{msg}</ul>,
              li: (...msg) => <li key={msg}>{msg}</li>,
              mailme: (...msg) => (
                <a href='mailto: anton.averin.photo+arcpanel@gmail.com'>
                  {msg}
                </a>
              ),
              winver: (...msg) => (
                <a
                  target='_blank'
                  href='https://support.microsoft.com/en-us/help/13443/windows-which-operating-system'
                >
                  {msg}
                </a>
              )
            }}
          />
        </LandingRowContent>
      </LandingRow>
    </IndexpageRoot>
  )
})
