import styled, { css } from "styled-components"

import { device, core } from "@src/style"

import { LandingRowContent, Column, Post, Row } from "@src/style/globalStyle"
import { SmallBlueButton } from "@components/buttons/style"
import Slider from "react-slick"

export const IndexpageRoot = styled.div`
  background-color: ${(props) => props.theme.main.bg};
  color: ${(props) => props.theme.main.textColor};
`

export const Previewer = styled.div`
  background-image: url("/maskso/_main.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
  background-color: rgb(40, 60, 82);

  @media ${device.laptopL} {
    background-size: 1440px;
    background-position: center top;
  }
`

export const FullscreenWrapper = styled.div`
  width: 100%;
  color: white;
`

export const Header = styled.header`
  width: auto !important;
  margin: 0 auto;
  ${(props) => props.theme.widthLimiter}

  display: flex;
  flex-direction: column;
`

export const LandingRowBuyButtonsContent = styled(LandingRowContent)`
  min-width: 500px !important;
  max-width: 500px !important;
`

export const LeftColumn = styled(Column)`
  justify-content: center;
  padding-right: 2rem;
`
export const RightColumn = styled(Column)`
  justify-content: center;
  padding-left: 2rem;
`

export const VideoSlider = styled(Slider)`
  padding: 1rem 0px;
  position: relative;

  .slick-prev,
  .slick-next {
    z-index: 999;
    height: 100%;
    width: 12vw;

    :hover {
      opacity: 0.5;
      background-color: ${(props) => props.theme.colors.accent};
    }
  }

  .slick-prev {
    left: unset;
  }

  .slick-next {
    right: 0px;
  }

  .slick-dots {
    position: unset;
    bottom: 0px;

    li {
      button:before {
        opacity: 1;
        color: white;
      }
    }
    li.slick-active {
      button:before {
        opacity: 1;
        color: ${(props) => props.theme.colors.accent};
      }
    }
  }
`

export const RoundImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const RoundImage = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;

  img {
    width: 100%;
    height: auto;
  }

  @media ${device.laptop} {
    width: 200px;
    height: 200px;
  }
  @media ${device.laptopL} {
    width: 300px;
    height: 300px;
  }
`

export const Grid = styled.div`
  display: block;
  @media ${device.tablet} {
    display: flex;
    justify-content: space-between;

    ${Post} {
      width: 32%;
    }
  }
`
export const GridItem = styled(Post)``
export const DocsMessage = styled.div`
  align-items: center;
  width: 100%;
  @media ${device.tablet} {
    padding-right: 1rem;
  }
`
export const Docs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const DocsRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  padding-bottom: 1rem;

  @media ${device.laptopL} {
    flex-direction: row;
    justify-content: space-around;
  }
`

export const Video = styled.div`
  padding-top: 24px;
  padding-left: 16px;
  width: 100%;
`

export const Card = styled(Post)`
  cursor: pointer;
  width: 100%;
  @media ${device.tablet} {
    width: 340px;
    height: 350px;
  }
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const Right = styled.div`
  display: flex;
  justify-content: right;
`

export const H2 = styled(core.H2)`
  padding-top: 4rem;
`
export const H3 = styled(core.H3)`
  padding-top: 2rem;
`
export const H4 = styled(core.H4)`
  padding-top: 1rem;
`
export const CardH3 = styled(core.H3)``
export const PM = styled(core.PM)``
export const PXS = styled(core.PXS)``

export const BlueButton = styled(SmallBlueButton)`
  margin: 3rem 0px;
`
export const Mobile = styled(Column)`
  align-items: center;
  ${H2} {
    padding-top: 2rem;
  }
`
export const Desktop = styled(Row)`
  justify-content: space-around;
  padding: 64px 0px 64px 0px;
`
